import total from "../../icons/total.svg";
import react, { useState, useEffect } from "react";
import open from "../../icons/open.svg";
import completed from "../../icons/completed.svg";
import client from "../../icons/clientnew.svg";
import supplies from "../../icons/supplies.svg";
import DynamicTable from "../Tables";
import { Doughnut, Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";

import "./dashboard.css";

const Dashboard = (props) => {
  useEffect(() => {
    props.dashboard();
  }, []);
  const orderVsProductData = props?.dashboardData?.orderVsProduct || [];
  const supplyDetails = props?.dashboardData?.supplyDetails || [];
  const chartsData = {
    labels: orderVsProductData.map((item) => item.productId?.name || ""),
    datasets: [
      {
        data: orderVsProductData.map((item) => item.orderCount || 0),
        backgroundColor: ["#ff9933", "#7a5eea", "#5a4a99", "#90ee90","#ff0000"],
      },
    ],
  };  let navigate = useNavigate();
  const name = localStorage.getItem("name");

  const chartOptions = {
    responsive: true,
    cutout: "60%",
    plugins: {
      legend: {
        position: "bottom", // Set the legend position to 'bottom'
      },
    },
    maintainAspectRatio: false, // Set this to false to allow custom height and width
    height: 500,
    width: 400,
  };
  const barChartData = {
    labels: supplyDetails.map((item) => item.suppliesName),
    datasets: [
      {
        label: "Orders per Month",
        backgroundColor: "#1877F2",
        borderColor: "#1877F2",
        borderWidth: 1,
        hoverBackgroundColor: "#1877F2",
        hoverBorderColor: "#1877F2",
        data: supplyDetails.map((item) => item.suppliesQuantity),
      },
    ],
  };
  const boardField = [
    {
      id: 0,
      text: "Total orders",
      value: props?.dashboardData?.totalOrders || "-",
      icon: total,
    },
    {
      id: 1,
      text: "Active orders",
      value: props?.dashboardData?.openOrders || "-",
      icon: open,
    },
    {
      id: 2,
      text: "Completed orders",
      value: props?.dashboardData?.doneOrders || "-",
      icon: completed,
    },
    {
      id: 3,
      text: "Total clients",
      value: props?.dashboardData?.totalClients || "-",
      icon: client,
    },
    {
      id: 4,
      text: "Total suppliers",
      value: props?.dashboardData?.totalSuppliers || "_",
      icon: supplies,
    },
  ];
  const barchartOptions = {
    indexAxis: 'x', // Show horizontal bar chart
    plugins: {
      legend: {
        display: false // Hide legend
      },
      tooltip: {
        mode: 'index', // Show tooltip on hover
        intersect: false // Allow multiple tooltips
      }
    },
    maintainAspectRatio: false, // Set this to false to allow custom height and width
    responsive: true, // Make the chart responsive
    scales: {
      x: {
        display: false // Hide y-axis labels
      }
    }
  };
  const handleClick = () => {
    navigate("/home/orders");
  };
  const tableHeadings = [
    "Order created",
    "Client",
    "Quantity",
    "Due date",
    "Status",
  ];
  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "N/A";
    }

    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const orderRequiringAttention =
    props?.dashboardData?.orderRequiringAttention || [];

  const tablerows = orderRequiringAttention.map((order) => [
    formatDate(order.createdAt),
    order.client.name,
    `${order.totalProductsCount} items`,
    formatDate(order.dueDate),
    order.orderStatus,
  ]);

  // Redefine table ids
  const tableIds = orderRequiringAttention.map((order) => order.id);


  return (
    <div className="container-fluid dashboard">
      <div className="row row-header">
        <div className="col-12">
          <h1 className="dashboard-header">Hi, welcome back  {name} !</h1>
        </div>
      </div>
      <div className="row card-row">
        {boardField.map((field, index) => (
          <div className="col-2 card " key={index}>
            <div style={{ display: "flex" }}>
              <div className="icon-container">
                <img src={field.icon} className="dash-icons" />
              </div>
              <div className="text-cont">
                <h1 className="value">{field.value}</h1>
                <h1 className="card-text">{field.text}</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12 tab-header">
          <h1 className="sub-header">Orders Requiring Attention</h1>
        </div>
        <div className="col-12 tab">
          {tablerows.length !== 0 ? (
            <DynamicTable
              ids={tableIds}
              headings={tableHeadings}
              rows={tablerows}
              showInfoButton={false}
              highlightedOrders={[1]}
              showStatus={true}
            />
          ) : (
            <div className="no-date">
              <p className="tab-in">
                It looks like you haven't Created any orders yet. when you're
                ready, your <br />
                orders will be displayed here for easy tracking and management.
              </p>
            </div>
          )}
        </div>
        <div className="orderLink">
        <a style={{color:"blue",textDecoration: "underline", cursor: "pointer"}} onClick={handleClick}>Go to Orders</a>
        </div>
     
      </div>
      <div className="row graph-row">
        <div className="col-md-4 graph1 mx-2">
          <h1 className="graph-header">Orders by product category</h1>
          <div className="first-graph">
            {orderVsProductData.length !== 0 ? (
              <Doughnut data={chartsData} options={chartOptions} />
            ) : (
              <p className="no-information">
                No information available to display
              </p>
            )}
          </div>
        </div>
        <div className="col-md-4 graph2 mx-2">
          <h1 className="graph-header">Supplies by Category</h1>
         
            {supplyDetails.length !== 0 ? (
               <div className="second-graph">
              <Bar data={barChartData} options={barchartOptions}/>
              </div>
            ) : (
              <p className="no-information1">
                No information available to display
              </p>
            )}
          
        </div>
      </div>
      {/* <div className="btn-div">
      {(tablerows.length === 0 )?(
        <button className="gobutton" onClick={handleClick}>
          Go to Orders
        </button>
      ):("")}
      </div> */}
    </div>
  );
};
export default Dashboard;
